import { apiUrls } from 'libs';
import { GET, POST } from 'libs/httpMethods';
import { apiCall } from 'libs/authenticationService';

const {
  PATIENTS_SEARCH_SUGGESTIONS,
  PATIENTS_SEARCH,
  GET_PROVIDERS,
  CREATE_USER,
  VAULT_PATIENTS_SEARCH_SUGGESTIONS,
  VAULT_PATIENTS_DETAILED,
} = apiUrls.admin;

export const reqSuggestPatients = ({
  params,
}) => apiCall(GET, PATIENTS_SEARCH_SUGGESTIONS, { params });

export const reqSuggestPatientsPost = ({
  params,
  data,
}) => apiCall(POST, PATIENTS_SEARCH_SUGGESTIONS, { params, data });

export const reqSearchPatients = ({
  params,
}) => apiCall(GET, PATIENTS_SEARCH, { params });

export const reqSearchPatientsPost = ({
  params,
  data,
}) => apiCall(POST, PATIENTS_SEARCH, { params, data });

export const reqGetProviders = () => apiCall(GET, GET_PROVIDERS);

export const reqCreateUser = ({ providerId, data }) => apiCall(POST, CREATE_USER(providerId), { data });

export const reqVaultSuggestPatients = ({
  params,
}) => apiCall(GET, VAULT_PATIENTS_SEARCH_SUGGESTIONS, { params });

export const reqGetVaultPatientDetail = ({ data }) => apiCall(POST, VAULT_PATIENTS_DETAILED, { data });

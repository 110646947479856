import React, { useState } from 'react';
import { path, isEmpty } from 'ramda';
import Layout from 'components/Layout';
import Table from 'components/Patients/Table';
import SearchForm from 'components/Patients/SearchForm';
import Icon from 'components/Icon';

import getProviderId from 'utils/getProviderId';
import getOrganizationId from 'utils/getOrganizationId';

import { reqSearchPatientsPost } from 'api/patients';

const SearchPage = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const providerId = getProviderId();
  const defaultProviderId = sessionStorage.getItem('DEFAULT_PROVIDER_ID');
  const organizationId = defaultProviderId === providerId ? getOrganizationId() : undefined;

  const onRequestOptions = (value) => ({
    providerId,
    organizationId,
    data: { searchQuery: value },
  });

  const extractPatientsFromResponse = path(['data', 'patients']);
  const suggestionsRequest = (value) => reqSearchPatientsPost(onRequestOptions(value));

  const onSuggestionSelected = (suggestionValue, suggestion) => {
    setSearchResults([suggestion]);
  };

  const submitEvent = (input) => {
    setSearchQuery(input);
    reqSearchPatientsPost(onRequestOptions(input))
      .then((response) => {
        const data = extractPatientsFromResponse(response);
        setSearchResults(data);
      });
  };

  return (
    <Layout>
      <div className="search-patients-results d-flex flex-column h-100">
        <div>
          <div className="text-center">
            <h1 className="h4 font-weight-normal letter-spacing mb-4">Search Patients</h1>
            <div className="text-tuna letter-spacing mb-4">
              Enter name, MRN, phone number to find patients who started
              or have completed Payment Plan Enrollment
            </div>
          </div>
          <div className="row justify-content-md-center m-3">
            <div className="col-md-4">
              <SearchForm
                value={searchInput}
                onInputChange={setSearchInput}
                suggestionsRequest={suggestionsRequest}
                extractSuggestionsFromResponse={extractPatientsFromResponse}
                onSuggestionSelected={onSuggestionSelected}
                submitEvent={submitEvent}
              />
            </div>
          </div>
          {!isEmpty(searchResults) && !isEmpty(searchQuery) && (
            <div className="text-center">
              <p className="text-large text-tuna mb-1">
                Showing results for
                {' '}
                <b>{searchQuery}</b>
              </p>
            </div>
          )}
        </div>
        {!isEmpty(searchResults) && (
          <Table data={searchResults} />
        )}
        {isEmpty(searchResults) && !isEmpty(searchQuery) && (
          <div className="flex-fill d-flex flex-column justify-content-center align-items-center pb-12">
            <p className="text-large text-tuna mt-3 mb-12 text-center">
              <Icon name="band-aids" className="search-patients-results__icon mb-3" />
              <br />
              No results found for
              {' '}
              <b>{searchQuery}</b>
            </p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SearchPage;

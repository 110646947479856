import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import queryString from 'qs';
import { isEmpty, path, trim } from 'ramda';

import { reqSearchPatientsPost, reqSuggestPatientsPost } from 'AdminSite/api/admin';
import Layout from 'components/Layout';
import Table from 'components/Patients/Table';
import SearchForm from 'components/Patients/SearchForm';
import Select, { createFilter } from 'react-select';
import { providerSelectFilterConfig, providerSelectCustomStyles } from './PatientsSearch.config';
import NoResultsFound from './NoResultsFound';
import SearchResultsTableInfo from './SearchResultsTableInfo';

const PatientsSearch = ({
  adminGetProviders,
  providers,
  startSpinner, stopSpinner,
}) => {
  const history = useHistory();
  const { providerId: providerIdFromQueryParam } = queryString.parse(
    history.location.search, { ignoreQueryPrefix: true },
  );
  const [provider, setProvider] = useState((providers.length && providers[0]) || null);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [resultsCount, setResultsCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (providerIdFromQueryParam && providers.length) {
      const selectedProvider = providers.find((p) => p.providerId === providerIdFromQueryParam);
      if (selectedProvider) setProvider(selectedProvider);
    }
  }, [providerIdFromQueryParam, providers]);

  useEffect(() => {
    startSpinner();
    adminGetProviders().finally(stopSpinner);
  }, []);

  const [searchResults, setSearchResults] = useState([]);

  const handleSearchResponse = (res) => {
    const { patients, total } = res.data;
    setSearchResults(patients);
    setResultsCount(patients.length);
    setTotalCount(total);
  };

  const suggestionsRequest = (value) => reqSuggestPatientsPost({
    params: {
      providerId: provider.providerId,
    },
    data: { searchQuery: trim(value || '') },
  });

  const pushHistoryWithSearchParams = () => history.push(`/admin/patients-search?providerId=${provider.providerId}`);

  const onSuggestionSelected = (suggestionValue, suggestion) => {
    pushHistoryWithSearchParams();
    setSearchQuery(suggestionValue);
    setSearchResults([suggestion]);
  };

  const submitEvent = (input) => {
    setSearchQuery(input);
    const trimmedInput = trim(input || '');
    if (provider) {
      pushHistoryWithSearchParams();
      reqSearchPatientsPost({ params: { providerId: provider.providerId }, data: { searchQuery: trimmedInput } })
        .then(handleSearchResponse);
    }
  };

  const shouldRenderSuggestions = (v) => provider && v && v.trim().length > 2;

  return (
    <Layout>
      <div className="flex-column h-100">
        <div>
          <div className="text-center">
            <h1 className="h4 font-weight-normal letter-spacing mb-4">Search Patients</h1>
            <div className="text-tuna letter-spacing mb-4">
              Enter name, MRN, phone number to find patients who started
              or have completed Payment Plan Enrollment
            </div>
          </div>
          { providers.length && (
          <div className="row justify-content-md-center">
            <div className="col-md-3">
              <Select
                value={provider}
                onChange={setProvider}
                options={providers}
                getOptionLabel={(option) => option.providerName}
                getOptionValue={(option) => option.providerId}
                styles={providerSelectCustomStyles}
                filterOption={createFilter(providerSelectFilterConfig)}
              />
            </div>
            <div className="col-md-5">
              <SearchForm
                onInputChange={setSearchInput}
                suggestionsRequest={suggestionsRequest}
                extractSuggestionsFromResponse={path(['data', 'patients'])}
                shouldRenderSuggestions={shouldRenderSuggestions}
                onSuggestionSelected={onSuggestionSelected}
                submitEvent={submitEvent}
                value={searchInput}
              />
            </div>
          </div>
          )}
        </div>
        {!isEmpty(searchResults) && (
          <>
            <SearchResultsTableInfo
              searchTerm={searchQuery}
              resultsCount={resultsCount}
              totalCount={totalCount}
            />
            <Table data={searchResults} />
          </>
        )}
        {isEmpty(searchResults) && searchQuery && (
          <NoResultsFound searchTerm={searchQuery} />
        )}
      </div>
    </Layout>
  );
};

PatientsSearch.propTypes = {
  adminGetProviders: PropTypes.func.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({
    providerId: PropTypes.string.isRequired,
    providerName: PropTypes.string.isRequired,
  })).isRequired,
  startSpinner: PropTypes.func.isRequired,
  stopSpinner: PropTypes.func.isRequired,
};

export default PatientsSearch;
